.status-loading {
    background-color: orange;
}

.status-on {
    background-color: green;
}

.status-off {
    background-color: red;
}

.status-loading:hover,
.status-loading:focus {
    background-color: #ffdeaf;
}

.status-on:hover,
.status-on:focus {
    background-color: #a8e3a8;
}

.status-off:hover,
.status-off:focus{
    background-color: #ffadad;
}

.button {
    color: white;
    border-radius: 8px;
    border-style: none;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    line-height: 20px;
    list-style: none;
    margin: 0;
    outline: none;
    padding: 10px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color 100ms;
    vertical-align: baseline;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.status-container {
    display: flex;
    align-items: flex-end;
    background-color: #212937;
    border-radius: 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    padding: 5px 20px 10px;
}

.stripe {
    border-radius: 2px;
}

.status-title {
    font-weight: 1000;
    font-stretch: extra-expanded;
    font-variation-settings: "GRAD" 400, "opsz" 17;
}

.status-text {
    font-weight: 1;
    font-variation-settings: "GRAD" 1000, "opsz" 17;
    font-stretch: extra-expanded;
}

