.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.mobile-nav-menu {
  display: flex; /* Standardmäßig ausgeblendet */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60px; /* Positionierung anpassen */
  right: 0; /* Positionierung anpassen */
  background-color: #333; /* Hintergrundfarbe anpassen */
  width: 150px; /* Breite anpassen */
  z-index: 1;
}

.mobile-nav-menu-active {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Horizontal zentrieren */
  align-items: center; /* Vertikal zentrieren, falls erforderlich */
}


.mobile-nav-icon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
}

.mobile-nav-icon-active + .mobile-nav-menu {
  display: flex; /* Angezeigt, wenn .mobile-nav-icon aktiv ist */
  flex-direction: column;
}

.mobile-nav-menu-active .nav-link {
  color: #fff;
  text-decoration: none;
  padding: 10px;
}

.mobile-nav-menu-active .nav-link:hover {
  background-color: #555;
}

#dropdown-basic  {
  background-color: cornflowerblue;
}


